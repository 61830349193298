import React from 'react';
import BaseApp from "@gotombola/react-ds/lib/misc/App";
import {TemplatesContextProvider} from "@gotombola/react-modules-engine/lib/contexts/TemplatesContext";
import {createI18n} from "./utils/common";

// import css styles
import './styles/generated.css';
import './styles/react-bnb-gallery-style.css';

createI18n();

const defaultTemplateDefinitions = {};

export default function App({templateDefinitions = defaultTemplateDefinitions, ...props}: any) {
    return (
        <TemplatesContextProvider value={templateDefinitions}>
            <BaseApp {...props} />
        </TemplatesContextProvider>
    );
}